"use client";

import {useEffect} from "react";
import {usePathname} from "next/navigation";
import ErrorBlock from "@/components/molecules/ErrorBlock.tsx";

interface ErrorPageProps {
  error: Error;
  reset: () => void;
}

const ErrorPage = ({error, reset}: ErrorPageProps) => {
  useEffect(() => {
    console.error(error);
  }, [error]);

  const pathname = usePathname();

  return (
    <main className="grid place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8">
      <ErrorBlock error={error} reset={reset}/>
    </main>
  );
};

export default ErrorPage;
