import Button from "@/components/atoms/Button";

interface ErrorBlockProps {
  error?: Error;
  reset?: () => void;
}

export const ErrorBlock = ({error, reset}: ErrorBlockProps) => {
  return (
    <div className="text-center">
      <p className="text-base font-semibold text-secondary">Oeps...</p>
      <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">
        {error?.name ?? "Er ging iets mis"}
      </h1>
      <p className="mt-6 lg:mt-12 text-lg leading-7 text-gray-600">
        {error?.message ?? "We kunnen deze pagina momenteel niet ophalen. Probeer later opnieuw of neem contact met ons op."}
      </p>
      <div className="mt-10 lg:mt-20 flex items-center justify-center gap-x-4">
        <Button as="link" href={`/`} className="btn-primary">Naar de startpagina</Button>
        {reset && (
          <Button onClick={() => reset()} className="btn-ghost">Probeer opnieuw</Button>
        )}
      </div>
    </div>
  );
};

export default ErrorBlock;
